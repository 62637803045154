.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 5px;
}

.title-container {
  text-align: center;
}

.logo {
  width: 150px;
  filter: invert(var(--image-filter));
}

.theme {
  cursor: pointer;
  font-size: 22px;
}

@media only screen and (max-width: 430px) {
  .Header {
    padding: 10px 10px 3px;
  }

  .logo {
    width:100px;
  }

  .theme {
    font-size: 18px;
  }
}
