.Button {
  padding: 10px 40px;
  border-radius: 40px;
  background: var(--primary-colour);
  outline: none;
  border: 0;
  cursor: pointer;
  font-weight: 600;
}

.Button:active {
  box-shadow: 2px 1px 4px inset var(--keys);
  transition: all .2s ease-in;
}
