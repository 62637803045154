.rule-list li {
  font-size: 16px;
  margin-bottom: 10px;
}

.section.start-game {
  margin-top: 20px;
  text-align: center;
}

.section {
  margin-bottom: 30px;
}

.examples {
  margin-top: 10px;
}

.example-word {
  display: flex;
}

.example-word .Letter {
  width: 30px;
  height: 30px;
}

.example-word .Letter .letter-value {
  font-size: 20px;
}

.example-word-key {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.example-word .Key {
  width: 25px;
  height: 25px;
  font-size: 14px;
}

.section.footer {
  text-align: center;
  font-size: 12px;
  border-bottom: 0;
}
