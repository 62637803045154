.Keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.keyboard-row {
  display: flex;
}

.Key {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
  background: var(--accent);
  cursor: pointer;
  font-weight: 600;
}

.Key:active {
  background: var(--keys);
  transition: all .2s ease-in;
}

@media only screen and (max-width: 430px) {
  .Key {
    margin: 3px;
    width: 30px;
  }
}
