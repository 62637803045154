.Timer {
  display: inline-block;
  text-align: center;
}

.timer-contents {
  font-size: 32px;
  font-weight: 100;
}

.seconds {
  font-size: 20px;
}

@media only screen and (max-width: 430px) {
  .timer-contents {
    font-size: 18px;
  }
}
