.modal-sub-title {
  width: 100%;
  margin-bottom: 10px;
}

.section {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--accent);
}

.score-row {
  display: flex;
  justify-content: space-around;
  margin: 10% 0;
}

.metric {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.metric-score {
  font-size: 22px;
  font-weight: 600;
}

.metric-title {
  font-size: 14px;
}

.share-row {
  text-align: center;
}
