.Modal {
  position: absolute;
  width: 100%;
  z-index: 998;
  height: 100vh;
  top: 0;
  left: 0;
}

.modal-dialog {
  z-index: 999;
  box-shadow: 1px 2px 6px var(--accent);
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  background: var(--background);
  width: 60%;
  margin: auto;
  min-height: 650px;
  max-width: 500px;
}

.modal-header {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  font-size: 24px;
}

.close-button {
  cursor: pointer;
  font-size: 18px;
  color: var(--accent);
}

.close-button:hover {
  color: var(--text-main);
}

.modal-body {
  padding: 15px 32px;
}

.modal-title {
  font-family: zilla-slab, 'serif';
  display: inline-block;
  font-weight: 600;
  font-size: 24px;
  padding: 5px 5px 5px 0;
  margin-bottom: 10px;
}

.modal-sub-title {
  font-family: zilla-slab, 'serif';
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-secondary);
}

@media only screen and (max-width: 430px) {
  .modal-dialog {
    width: 95%;
    min-height: 600px;
  }

  .modal-header {
    padding: 5px 15px;
  }

  .modal-body {
    padding: 5px 15px;
  }
}
