.Word {
  display: flex;
  justify-content: center;
}

.Letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 1px solid var(--text-secondary);
  margin: 5px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.letter-value {
  font-size: 32px;
  color: var(--text-main);
  text-transform: capitalize;
}

.highlight {
  background: var(--primary-colour);
  border: 1px solid var(--text-main);
  transition: all .2s ease-in;
}

.highlight > .letter-value {
  font-weight: 600;
}

@media only screen and (max-width: 430px) {
  .Letter {
    width: 50px;
    height: 50px;
  }

  .letter-value {
    font-size: 24px;
  }
}
