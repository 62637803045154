:root {
  --primary-colour: #7af599;
  --secondary-colour: #74c488;
  --background: #fcfcfc;
  --text-main: #191a19;
  --accent: #c9c9c9;
  --text-secondary: #8c8c8c;
  --keys: #8c8c8c;
  --warning: #e34d39;
  --image-filter: 0;
}

.dark {
  --primary-colour: #187a12;
  --background: #191a19;
  --text-main: #fcfcfc;
  --accent: #636363;
  --keys: #454545;
  --image-filter: 1;
}

@font-face {
  font-family: 'open-sans';
  src: local('OpenSans'), url('../fonts/OpenSans.ttf') format('truetype');
}

@font-face {
  font-family: 'zilla-slab';
  src: local('ZillaSlab'), url('../fonts/ZillaSlab.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: open-sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-main);
  background: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
