.Score {
  display: inline-block;
}

.score-count {
  font-size: 32px;
}

@media only screen and (max-width: 430px) {
  .score-count {
    font-size: 22px;
  }
}
