.WordGame {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.game-metrics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  width: 200px;
  padding: 10px;
}

.metric-icon {
  margin-right: 5px;
}

.warning {
  margin-top: 5px;
  color: var(--warning);
  text-align: center;
  height: 30px;
}
